 import React from "react";
import { Link } from "gatsby";

export default () => (
  <nav className="navbar navbar-expand-sm navbar-light bg-white">
    <div className="container">
      <div>
        <Link className="navbar-brand" to="/">
        {String.fromCharCode(171) + ' Back'}
      </Link>
        {/* <Link className="navbar-brand" to="/about">
          about me
      </Link> */}
      </div>

      {/* <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link className="nav-link" to="/about">
              About
            </Link>
          </li>
        </ul>
      </div> */}
    </div>
  </nav>
);
